<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-row class="bradcrumb">
            <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
            <span class="child"> Book Bill Summary Report</span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Book Bill Summary Report</v-card-title>
            <v-card-text>
                <v-row style="" class="pt-5">
                    <v-col cols="12" md="3" sm="3">
                      <label class="add-text">Purchase From Date</label>
                      <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y  full-width max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field class="text" v-model="fromDate"  placeholder="Purchase From Date" append-icon="event" persistent-hint dense readonly outlined v-on="on" :min="nowDate" :max="getEndDate"></v-text-field>
                        </template>
                        <v-date-picker v-model="fromDate" no-title @input="fromDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <label class="add-text">Purchase To Date</label>
                      <v-menu v-model="toDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y  full-width max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field class="text" v-model="toDate" placeholder="Purchase To Date" append-icon="event" persistent-hint dense readonly outlined v-on="on" :min="nowDate" :max="getEndDate"></v-text-field>
                        </template>
                        <v-date-picker v-model="toDate" no-title @input="toDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                        <label class="add-text">Series</label>
                        <v-autocomplete v-model="selectedseries" dense outlined label="Series" :items="serieslist" item-text="name" item-value="id" ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                       <label class="add-text">Department</label>
                       <v-autocomplete v-model="selecteddepartment" dense outlined label="Department" :items="departmentlist" item-text="name" item-value="id"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <center><b>OR</b></center>
                    </v-col>
                    <v-col cols="12" md="4" sm="4"></v-col>
                    <v-col cols="12" md="4" sm="4">
                        <label class="add-text">Bill No.</label>
                        <v-text-field v-model="billno" dense outlined label="Bill No"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="4"></v-col>
                </v-row>
                <v-row class="pb-3" justify="center" align="center">
                    <v-btn class="m-2" color="info" @click="searchBill">Search</v-btn>
                </v-row>

               <v-data-table :headers="headers" :items="billList" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details></v-text-field>
                            <span style="width: 20px"></span>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; 

export default {
  data: () => ({
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    overlay: false,
    departmentlist: [],
    serieslist: [],
    selecteddepartment: "",
    selectedseries: "",
    billno : "",
    billList: [],
    fromDateMenu: false,
    toDateMenu: false,
    fromDate: "",
    toDate: "",
    nowDate: '0000-01-01',
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Bill No.",
        align: "left",
        sortable: true,
        value: "billNoAndDate",
      },
      {
        text: "Date",
        align: "left",
        sortable: true,
        value: "purchaseDate",
      },
      {
        text: "Source",
        align: "left",
        sortable: true,
        value: "vendor",
      },
      {
        text: "PO No. & Date",
        align: "left",
        sortable: true,
        value: "poNoAndDate",
      },
      {
        text: "Accession No.",
        align: "left",
        sortable: true,
        value: "accession_number",
      },
      {
        text: "Total volume",
        align: "left",
        sortable: true,
        value: "volume",
      },
      {
        text: "Amount Rs.(Gross)",
        align: "left",
        sortable: true,
        value: "price",
      },
      {
        text: "Disc.%",
        align: "left",
        sortable: true,
        value: "discount",
      },
      {
        text: "Discount Rs.",
        align: "left",
        sortable: true,
        value: "discountprice",
      },
      {
        text: "Credit Note",
        align: "left",
        sortable: true,
        value: "",
      },
      {
        text: "Net Pay",
        align: "left",
        sortable: true,
        value: "bookprice",
      },
       {
        text: "Book Received on.",
        align: "left",
        sortable: true,
        value: "",
      },
       {
        text: "Remarks",
        align: "left",
        sortable: true,
        value: "",
      },
      
    ],
    
  }),
  computed: {
    getEndDate() {
        var endDate = new Date();
        return endDate.toISOString().slice(0,10)
    },
  },

  mounted() {
    this.fromDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.toDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.onLoad();
  },

  methods: {
    onLoad() {
        this.overlay = true;
        axios
          .post("/Librarian/getSeriesAndDepartment")
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.departmentlist = res.data.departmentlist;
              this.serieslist = res.data.serieslist;

              if(this.departmentlist.length > 1){
                  this.selecteddepartment="All";
                  this.departmentlist.push({name: 'All', text: ''});
              }else if(this.departmentlist.length > 0){
                  this.selecteddepartment= this.departmentlist[0].id;
              }

              if(this.serieslist.length > 1){
                  this.selectedseries="All";
                  this.serieslist.push({name: 'All', text: ''});
              }else if(this.serieslist.length > 0){
                  this.selectedseries= this.serieslist[0].id;
              }
            }
          }).catch((error) => {
            this.showSnackbar("#b71c1c", error); 
            window.console.log(error);
          }) .finally(() => {});
    },
    searchBill() {
      
      var params = {
        selecteddepartment: this.selecteddepartment,
        selectedseries: this.selectedseries,
        fromDate:this.fromDate,
        toDate : this.toDate,
        billno : this.billno,
      };
      this.overlay = true;
      axios
        .post("/Librarian/getBillSummaryData",params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.billList = res.data.billList;
            if(this.billList.length == 0){
              this.showSnackbar("#b71c1c", "Data Not Found !!");
            }
          }
        }).catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); 
          window.console.log(error);
        }).finally(() => {
        });
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}
.col-sm-12.col-md-4.col-lg-4.col-12 {
    padding-top: 0px;
    padding-bottom: 0px;
}

</style>